import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import CaseStudiesPage from '../ui/pages/CaseStudiesPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const CaseStudies = props => (
  <>
    <HelmetComponent
      title={metaTags.caseStudiesTitle}
      description={metaTags.caseStudies}
      page="de/case-studies"
    />
    <AppWrapper {...props} lang="de">
      <CaseStudiesPage />
    </AppWrapper>
  </>
)

export default CaseStudies
